import './bootstrap.js';
import './swup.js';

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';

import slider from './components/slider.js';
import header from './components/header.js';
import teaser from './components/teaser.js';
import contactForm from './components/contactForm.js';
import sailboatData from './components/sailboatData.js';
import cookieConsent from './components/cookieConsent.js';
import newsletterSignup from './components/newsletterSignup.js';
import sliderVideoPlayer from './components/sliderVideoPlayer.js';

window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(collapse);

document.addEventListener('alpine:init', () => {
    Alpine.data('slider', slider);
    Alpine.data('header', header);
    Alpine.data('teaser', teaser);
    Alpine.data('contactForm', contactForm);
    Alpine.data('sailboatData', sailboatData);
    Alpine.data('cookieConsent', cookieConsent);
    Alpine.data('newsletterSignup', newsletterSignup);
    Alpine.data('sliderVideoPlayer', sliderVideoPlayer);

    Alpine.store('themeColor', '#FFFFFF');
});

Alpine.start();
