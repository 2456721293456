export default () => ({
    isMobile: false,
    textRef: null,
    tableWidth: null,

    async init() {
        this.textRef = document.querySelector('#sailboatText');

        await this.$nextTick();
        this.onResize();
    },

    toggleMenu() {
        this.open = !this.open;
    },

    onResize() {
        this.isMobile = window.innerWidth < 769;
        this.tableWidth = this.isMobile
            ? 'auto'
            : this.textRef.offsetWidth + 'px';
    },
});
