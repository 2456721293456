import Swup from "swup";
import SwupProgressPlugin from "@swup/progress-plugin";
import SwupPreloadPlugin from "@swup/preload-plugin";
import SwupFragmentPlugin from "@swup/fragment-plugin";
import SwupMorphPlugin from "swup-morph-plugin";

export const newsPattern = ["/(en/news|news)(\\/?)", "/(en/news|news)(\\/?)\\?page=:num(\\d+)"];
export const sailboatPattern = "/(en/sailboats|segelboote)/:sailboat";

export default new Swup({
    containers: [
        "#main",
        "#footer", // newsletter link changes depending on site
    ],
    plugins: [
        new SwupMorphPlugin({
            containers: [
                "#localeSwitch",
                // "#footer",
                "#sailboatNav",
            ],

        }),
        new SwupFragmentPlugin({
            rules: [
                {
                    from: sailboatPattern,
                    to: sailboatPattern,
                    containers: ["#sailboatEntry"],
                },
                {
                    from: newsPattern,
                    to: newsPattern,
                    containers: ["#newsGrid", "#loadMoreNews"],
                    scroll: '#firstNewsOnPage',
                },
            ],
        }),
        new SwupProgressPlugin({ delay: 500 }),
        new SwupPreloadPlugin(),
    ],
});
