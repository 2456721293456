import * as CookieConsent from "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";

export default () => ({
    init() {
        CookieConsent.run({

            // onConsent: ({cookie}) => {
            //     console.log('cookieConsent', cookie);
            // },

            // can be disabled if we can make it persisten, eg. use swup
            disablePageInteraction: true,

            guiOptions: {
                consentModal: {
                    layout: "box",
                    position: "bottom right",
                    transition: "slide",
                    flipButtons: false,
                    equalWeightButtons: true,
                },
            },

            categories: {
                necessary: {
                    readOnly: true,
                    enabled: true,
                },
                analytics: {
                    autoClear: {
                        cookies: [
                            {
                                name: /^(_ga|_gid)/,
                            },
                        ],
                    },
                },
            },

            language: {
                default: "en",
                autoDetect: "document",
                translations: {
                    en: "/lang/cookie-consent/en.json",
                    de: "/lang/cookie-consent/de.json",
                },
            },
        });
    },
});
