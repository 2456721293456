export default () => ({
    init() {
        this.$store.themeColor = window.themeColor;
        this.setThemeColor();
    },

    destroy() {
        this.$store.themeColor = '#FFFFFF';
        this.setThemeColor();
    },

    setThemeColor() {
        const metaThemeColor = document.querySelector('meta[name=theme-color]');
        metaThemeColor.setAttribute('content', this.$store.themeColor);
    },
});
