import swup from "../swup.js";

export default () => ({
    open: false,
    headerHeight: 45,
    themeColor: "#ffffff",

    init() {
        this.onResize();
        this.initLottie();
        this.setThemeColor();

        this.$watch("open", async () => {
            if (this.open) {
                await this.$nextTick();
                document.activeElement.blur();
            }

            setTimeout(() => {
                this.setThemeColor();
            }, 60);
        });

        swup.hooks.on("content:replace", () => {
            this.open = false;
        });

        swup.hooks.on("link:self", () => {
            this.open = false;
        });
    },

    setThemeColor() {
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        metaThemeColor.setAttribute(
            "content",
            this.open ? window.themeColor : this.$store.themeColor,
        );
    },

    async initLottie() {
        const hasHover = window.matchMedia("(any-hover: hover)");
        if (hasHover.matches) {
            import("@lottiefiles/lottie-player");
        }
    },

    toggleMenu() {
        this.open = !this.open;
    },

    onResize() {
        this.headerHeight = this.$refs.mainMenu.offsetHeight;
    },

    get fillerHeight() {
        return this.headerHeight + "px";
    },

    get overlayHeight() {
        return this.headerHeight + 40 + "px";
    },
});
