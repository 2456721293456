import Swiper from 'swiper';
import { Autoplay, Keyboard, Navigation } from 'swiper/modules';
import 'swiper/css';

const autoplayDelay = 30000;

export default (hasVideos = false) => ({
    swiper: null,
    activeSlide: 0,

    async init() {
        this.initSwiper();

        await this.$nextTick();
        this.slideChanged();
    },

    destroy() {
        if (this.swiper) {
            this.swiper.destroy();
        }
    },

    initSwiper() {
        this.swiper = new Swiper(this.$refs.homeSlider, {
            modules: [Autoplay, Keyboard, Navigation],

            loop: !hasVideos,
            rewind: hasVideos,
            speed: 500,

            autoplay: {
                delay: autoplayDelay,
            },

            keyboard: {
                enabled: true,
            },

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        this.swiper.on('slideChange', () => {
            this.slideChanged();
        });
    },

    slideChanged() {
        this.activeSlide = this.swiper.realIndex;
    },
});
