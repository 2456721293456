import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';

export default () => ({
    initialised: false,
    expanded: false,
    success: false,
    formEl: null,
    FormieInstance: null,

    init() {
        setTimeout(() => {
            if (window.location.hash === '#newsletter') {
                this.expanded = true;
            }
        }, 300);

        this.$watch('expanded', () => {
            if(!this.expanded){
                history.replaceState("", "", window.location.pathname);
            } else {
                this.autofocus();
                this.initialise();
            }
        });
    },

    expand() {
        this.expanded = true;
    },

    initialise() {
        if (this.initialised) {
            return;
        }

        this.formEl = this.$el.querySelector('#newsletterSignup');

        // Initialise Formie
        this.FormieInstance = new Formie();
        this.FormieInstance.initForm(this.formEl);

        // Refresh the Captcha Tokens (since we do not reload the page after submit)
        const form = this.FormieInstance.getForm(this.formEl);
        this.FormieInstance.refreshFormTokens(form);

        this.formEl.addEventListener('onAfterFormieSubmit', (e) => {
            this.expanded = false;
            this.success = true;
        });

        this.initialised = true;
    },

    autofocus() {
        if(this.expanded) {
            setTimeout(() => {
                this.$focus.focus(this.$el.querySelector('form input[type=text]'))
            }, 300)
        }
    },

    destroy() {
        if(this.FormieInstance) {
            this.FormieInstance.destroyForm(this.formEl);
        }
    },
});
