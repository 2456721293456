import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';

export default () => ({
    formEl: null,
    FormieInstance: null,

    init() {
        this.formEl = this.$el.querySelector('form');

        // Initialise Formie
        this.FormieInstance = new Formie();
        this.FormieInstance.initForm(this.formEl);

        // const form = this.FormieInstance.getForm(this.formEl);
        // this.FormieInstance.refreshFormTokens(form);
    },

    destroy() {
        if(this.FormieInstance) {
            this.FormieInstance.destroyForm(this.formEl);
        }
    },
});
