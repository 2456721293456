import Plyr from 'plyr';

export default (slideIndex) => ({
    initialised: false,
    player: null,

    init() {
        this.slideChanged();

        this.$watch('activeSlide', () => {
            this.slideChanged();
        });
    },

    destroy() {
        if (this.player) {
            this.player.destroy();
        }
    },

    async slideChanged() {
        if (
            !this.initialised &&
            [slideIndex, slideIndex - 1].includes(this.activeSlide)
        ) {
            await this.initPlayer();
        }

        this.setPlayState();
    },

    async initPlayer() {
        // const Plyr = await import("plyr");

        this.player = new Plyr(this.$refs.player, {
            muted: true,
            autoplay: false,
            controls: [],
            autopause: true,
            volume: 0,
            loadSprite: false,
            loop: { active: true },
        });

        this.player.on('ready', (event) => {
            this.player.play();
        });

        this.player.on('playing', (event) => {
            this.setPlayState();
        });

        this.initialised = true;
    },

    setPlayState() {
        if (this.player) {
            if (this.activeSlide === slideIndex) {
                this.player.play();
            } else {
                this.player.pause();
            }
        }
    },
});
